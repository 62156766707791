import React, { FC } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  isSearchTermOverlayOpenState,
} from '../../../digando-overlay/search-term-overlay/states/search-term-overlay.states';
import {
  isGlobalSearchHeaderActiveState,
} from './../global-search.state';
import { SearchInputTermTypes, SuggestionTypes, useGlobalSearchContext } from '../../../../store/global-search.context';
import { isOnSearchPage } from '../../../../helper/is-on-search-page';
import { useGlobalSearchValues, useSetGlobalSearch } from '../../../../store/recoil/global-search';
import { useRouter } from 'next/router';
import styles from './term-field.module.scss';
import { GlobalSearchInput } from '@digando/common-ui';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

type FieldTermProps = {
  identifier: string;
  compact?: boolean;
}

export const TermField: FC<FieldTermProps> = ({ identifier, compact = false }) => {
  const [t] = useTranslation(['search-component', 'common']);
  const setIsGlobalSearchHeaderActive = useSetRecoilState(isGlobalSearchHeaderActiveState);
  const setIsSearchTermOverlayOpen = useSetRecoilState(isSearchTermOverlayOpenState);
  const { term } = useGlobalSearchValues();
  const { setTerm } = useSetGlobalSearch();
  const { openSuggestion } = useGlobalSearchContext();
  const router = useRouter();

  const onClearTerm = async (): Promise<void> => {
    // Reset search configuration
    setTerm({
      type: SearchInputTermTypes.TEXT,
      value: '',
    });

    // Forward to text search, when we are already on search page
    if (isOnSearchPage(router.pathname)) {
      const currentPath = new URL(router.asPath, 'https://www.digando.com').pathname;

      await router.push({
        pathname: currentPath,
        query: {
          q: undefined,
        },
      }, undefined, { shallow: true });
    }
  };

  return (
    <div className={classNames({
      [styles.container]: true,
      [styles.compact]: compact,
    })}>
      <GlobalSearchInput
        compact={compact}
        id={`global-search-term-${identifier}`}
        name={`global-search-term-${identifier}`}
        value={term?.value}
        defaultValue={term?.value}
        label={t('search-component:label-search-term')}
        placeholder={t('search-component:search-term-placeholder')}
        onChange={(value): void => {
          setIsGlobalSearchHeaderActive(true);
          openSuggestion(SuggestionTypes.TERM);
          setTerm({
            value: value,
            type: SearchInputTermTypes.TEXT,
          });
        }}
        onFocus={(event): void => {
          openSuggestion(SuggestionTypes.TERM);
          setIsSearchTermOverlayOpen(true);

          event.target.select();
        }}
        onDelete={onClearTerm}
      />
    </div>
  );
};
