import styled from '@emotion/styled';
import {
  FontSecondaryLight,
  FontSecondaryRegular,
  FontSecondarySemiBold,
  spacingCalculator,
} from '@digando/react-component-library';

export const ReactDatePickerStyle = styled.div`
  flex: 1;

  & > div {
    text-align: center;
  }

  .react-datepicker__navigation {
    align-items: center;
    background: none;
    display: block;
    height: auto;
    width: auto;
    text-indent: 0;
    top: 8px;
    font-size: 0;
    line-height: 0;
    margin-left: ${spacingCalculator(3)};
    margin-right: ${spacingCalculator(3)};

    span {
      color: var(--secondary-color);
    }

    .react-datepicker__navigation-icon:before {
      display: none;
    }

    &.react-datepicker__navigation--previous {
      transform: rotate(180deg);
    }
  }

  .react-datepicker__current-month {
    ${FontSecondarySemiBold};
    font-size: var(--font-size-headline-h3);
    line-height: 24px;
  }

  // Will fix the wrong selected daterange when the user go back to the datepicker

  .react-datepicker__month-container {
    .react-datepicker__day.react-datepicker__day--today:not(.react-datepicker__day--selected):not(.react-datepicker__day--outside-month):not(.react-datepicker__day--selecting-range-start) {
      border-color: var(--primary-color);
    }
  }
  .react-datepicker__month--selecting-range {
    .react-datepicker__day.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--outside-month) {
      background-color: var(--primary-color) !important;
      border-color: var(--primary-color);
      color: var(--white);
    }
  }

  .react-datepicker__day.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--outside-month) {
    background-color: var(--white) !important;
    border-color: var(--white);
    color: var(--black);

    &.react-datepicker__day--disabled,
    &.react-datepicker__day--excluded {
      color: var(--font-color-disabled);
      cursor: not-allowed;
    }
  }

  // Fix end

  .react-datepicker__day {
    border-radius: 0;
    border: 1px solid var(--white);

    ${FontSecondaryLight};
    margin-top: ${spacingCalculator(1)};
    margin-bottom: ${spacingCalculator(1)};
    margin-left: 0;
    margin-right: 0;

    &.react-datepicker__day--selected,
    &.react-datepicker__day--in-range {
      background-color: var(--primary-color);
      color: var(--white);
      border-color: var(--primary-color);
    }

    &.react-datepicker__day--in-selecting-range {
      color: var(--white);
    }

    &.react-datepicker__day--outside-month {
      background-color: transparent;
    }

    &.react-datepicker__day--disabled,
    &.react-datepicker__day--excluded {
      color: var(--font-color-disabled);
      cursor: not-allowed;
    }

    &.react-datepicker__day--today:not(.react-datepicker__day--selected):not(.react-datepicker__day--outside-month):not(.react-datepicker__day--selecting-range-start) {
      color: var(--primary-color);
    }

    &.react-datepicker__day:hover:not(.react-datepicker__day--selected) {
      background-color: inherit;
      border-color: var(--primary-color);
    }

    &.react-datepicker__day--in-range:not(.react-datepicker__year-text--in-selecting-range):not(.react-datepicker__day--outside-month) {
      background-color: var(--primary-color) !important;
      border-color: var(--primary-color);
      color: var(--white);

      &.react-datepicker__day--disabled,
      &.react-datepicker__day--excluded {
        color: var(--font-color-disabled);
      }
    }

    &.react-datepicker__day--selected.react-datepicker__day--outside-month {
      background-color: transparent;
    }
  }

  .react-datepicker__header {
    background-color: transparent;
    border: 0;

    .react-datepicker__current-month {
      margin-bottom: ${spacingCalculator(2)};
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__week-number,
  .react-datepicker__day {
    font-size: var(--font-size-headline-h4);
    line-height: 40px;
    width: 50px;
    margin: 0;
    padding: 0 10px;
  }

  .react-datepicker__day-name {
    ${FontSecondaryRegular};
    text-transform: uppercase;
  }

  .react-datepicker__week-number {
    ${FontSecondaryLight};
    font-size: 16px;
    color: var(--font-color-medium);
  }


  .react-datepicker {
    border: 0;
  }

  .react-datepicker__week .react-datepicker__day.react-datepicker__day--outside-month {
    border: none;
    font-size: 0;
  }

  .react-datepicker__week {
      margin: 10px 0;
  }

  .react-datepicker-popper {
    z-index: 110 !important;

    .react-datepicker {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
    }

    .react-datepicker__navigation {
      margin-left: ${spacingCalculator(0.5)};
      margin-right: ${spacingCalculator(0.5)};

      &.react-datepicker__navigation--next {
        margin-right: ${spacingCalculator(0)};
      }
    }

    .react-datepicker__day-name,
    .react-datepicker__week-number,
    .react-datepicker__day {
      font-size: var(--font-size-text);
      line-height: 30px;
      width: 35px;
      margin: ${spacingCalculator(0.5)};
    }

    .react-datepicker__month-container {
      margin-right: ${spacingCalculator(0)};
      margin-left: ${spacingCalculator(0)};
    }
  }

  .react-datepicker__month-container {
    margin-right: ${spacingCalculator(2)};
    margin-left: ${spacingCalculator(2)};
  }

  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    display: none;
  }
`;
