import { useCallback, useRef } from 'react';
import { ILocation } from '../../../interfaces/location.interface';
import { useProductSearchHelper } from '../../../hooks/use-product-search-helper';
import { useRouter } from 'next/router';
import { ssrLocationByPlaceId, ssrPlaces, ssrCategoryByText } from '../../../@types/codegen/page';
import { useCategoriesContext } from '../../../store/categories.context';
import { SearchInputTermTypes, useGlobalSearchContext } from '../../../store/global-search.context';
import { IDateRange } from '../../../interfaces/date-range.interface';
import {
  filterValueDateRangeSelector,
  filterValueLocationSelector,
  scrollPositionState, specificLocationRadiusState,
} from '../../../store/recoil/product-search';
import { useGlobalSearchValues, useSetGlobalSearch } from '../../../store/recoil/global-search';
import { useSetRecoilState } from 'recoil';

interface IHandleSearchSubmitProps {
  preselectedTenantKey?: string | null;
  newDateRange?: IDateRange;
}

let searchTimeout: NodeJS.Timeout;

export const useHandleSubmit = (): { handleSubmitSearch: (props?: IHandleSearchSubmitProps) => void } => {
  const router = useRouter();

  const { setLocation, setTerm } = useSetGlobalSearch();
  const { location, term } = useGlobalSearchValues();
  const { closeSuggestion } = useGlobalSearchContext();
  const { rootCategory } = useCategoriesContext();
  const { setTenantKeys } = useProductSearchHelper();
  const setDateRange = useSetRecoilState(filterValueDateRangeSelector);
  const setFilterValueLocation = useSetRecoilState(filterValueLocationSelector);
  const setSpecificLocationRadiusState = useSetRecoilState(specificLocationRadiusState);

  const normalizedTerm = term?.value?.toLowerCase() ?? '';

  const setScrollPosition = useSetRecoilState(scrollPositionState);
  /**
   * @fixme:
   * With this state we will block an additional submit when we remove the text from the search-field.
   * The reason for the additional submit is that the removal of the text in the text-field will trigger a change on the text-field and so we get automatically a trigger for the submit-search.
   * This submitted search will redirect the user to the root-category page.
   */
  const submitIsRunning = useRef<boolean>(false);

  const {
    refetch: refetchPlace,
  } = ssrLocationByPlaceId.usePage(() => ({
    variables: {
      id: '',
    },
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-first',
  }));

  const {
    refetch: locationSearchRefetch,
  } = ssrPlaces.usePage(() => ({
    variables: {
      params: {
        text: '',
      }
    },
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-first',
  }));


  const handleSubmitSearch = (props: IHandleSearchSubmitProps = {}) => {
    // catch multiple submits, this is not optimal but will solve some timing issues for now.
    // The better solution would be to handle submit in the search-box components correctly.
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      runSearch(props);
    }, 100);
  }

  /**
   * Check and resolve the search input data and redirect the user to the correct page.
   */
  const runSearch = useCallback(async ({
    preselectedTenantKey = null,
    newDateRange,
  }: IHandleSearchSubmitProps = {}): Promise<void> => {
    if (true === submitIsRunning.current) {
      return;
    }

    const routeUrl = new URL(rootCategory?.relativeUrl ?? '/', 'https://www.digando.com');
    let newLocation: ILocation | null = null;
    let placeId = location?.placeId ?? '';
    const isSavedAddressUsed = placeId.includes('saved-address');
    const savedAddressId = isSavedAddressUsed ? placeId : null;

    // We are already on a category page, so we will stay on this page.
    // Only the text search can now override the target page.
    if ('/baumaschinen-mieten/[[...categories]]' === router.pathname) {
      routeUrl.pathname = router.asPath;
    }

    if (term?.value) {
      routeUrl.searchParams.set('q', term.value);
    }

    submitIsRunning.current = true;
    setScrollPosition('');

    // Set tenant key when the search-box is for a specific tenant.
    if (null !== preselectedTenantKey) {
      setTenantKeys([preselectedTenantKey]);
    }

    /**
     * If we have a saved address, we will use the place id from the saved address.
     */
    if ('' === placeId || isSavedAddressUsed) {
      const matched = location?.name.match(/(?!\()[\w\s]+(?=\))/g) as RegExpMatchArray;

      const text = isSavedAddressUsed ? matched[0] : location?.name ?? '';

      if ('' !== text) {
        const placeRes = await locationSearchRefetch({
          params: {
            text,
          },
        });

        placeId = placeRes?.data.Geolocation_places?.data?.[0]?.id ?? '';
      }
    }

    /**
     * Load place details when we have a place id.
     * If we have a place id, we will use the place details for the search.
     */
    if (placeId) {
      const placeRes = await refetchPlace({
        id: placeId,
      });

      const placeDetails = placeRes?.data.Geolocation_locationByPlace;
      const radius = placeDetails?.radius ?? null;

      if (placeDetails?.name && placeDetails.lat && placeDetails.lng) {
        if (null !== radius) {
          setSpecificLocationRadiusState(radius);
        } else {
          setSpecificLocationRadiusState(null);
        }

        newLocation = {
          lat: placeDetails.lat,
          lng: placeDetails.lng,
        };

        setLocation({
          name: placeDetails.name || location?.name as string,
          placeId: isSavedAddressUsed ? savedAddressId as string : placeId,
        });
        setFilterValueLocation(newLocation);
      }
    } else {
      setFilterValueLocation(null);
    }

    /**
     * Handle category search
     *
     * If the search term is a category name, we will redirect the user to the category page.
     * Otherwise the user stays on the current category page.
     */
    if (normalizedTerm) {
      const categoryRes = await ssrCategoryByText.getServerPage({ variables: { text: normalizedTerm } });
      const category = categoryRes?.props?.data?.categoryByText ?? null;

      if (null !== category) {
        routeUrl.pathname = category.relativeUrl;
        routeUrl.searchParams.delete('q');

        setTerm({
          value: '',
          type: SearchInputTermTypes.TEXT,
        });
      }
    }

    // Handle daterange search
    if (newDateRange) {
      setDateRange({
        dateRange: newDateRange,
      });
    }

    closeSuggestion(true);

    // Generate query params for redirection.
    const query: {q?: string;} = {};

    if (routeUrl.searchParams.has('q')) {
      query.q = routeUrl.searchParams.get('q') as string;
    }

    await router.push(
      {
        pathname: decodeURIComponent(routeUrl.pathname.toString()).split("?")[0], // remove query params
        query,
      },
      undefined,
      { shallow: false, scroll: true },
    );
    submitIsRunning.current = false;
  }, [JSON.stringify(term), location?.placeId, location?.name]);

  return {
    handleSubmitSearch,
  };
};
