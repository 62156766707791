import React, { FC } from 'react';
import styles from './date-field.module.scss';
import { SuggestionTypes, useGlobalSearchContext } from '../../../../store/global-search.context';
import { GlobalSearchInput, Input } from '@digando/common-ui';
import { useTranslation } from 'next-i18next';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { Icon } from '@digando/react-component-library';
import { useSetRecoilState } from 'recoil';
import {
  isGlobalSearchHeaderActiveState
} from './../global-search.state';
import { useDateInfo } from '../../../../hooks/use-date-info';
import ReactDatePicker, { ReactDatePickerStyle } from './../../../date-picker/date-picker';

type ToDateFieldProps = {
  identifier: string;
  compact?: boolean;
}

export const ToDateField: FC<ToDateFieldProps> = ({ identifier, compact = false, }) => {
  const {
    openSuggestion,
    endDate,
    startDate,
    setEndDate,
  } = useGlobalSearchContext();
  const [t] = useTranslation(['search-component', 'common']);
  const setIsGlobalSearchHeaderActive = useSetRecoilState(isGlobalSearchHeaderActiveState);
  const dateInfo = useDateInfo();

  return (
    <>
      <div className={styles.desktopDateFieldWrapper}>
        <GlobalSearchInput
          id={`global-search-to-date-${identifier}`}
          name={`global-search-to-date-${identifier}`}
          value={endDate.format('DD.MM.YYYY')}
          defaultValue={endDate.format('DD.MM.YYYY')}
          label={t('search-component:label-end-date')}
          onChange={() => {
            return;
          }}
          onFocus={(event): void => {
            setIsGlobalSearchHeaderActive(true);
            openSuggestion(SuggestionTypes.DATE_END);
          }}
          compact={compact}
        />
      </div>

      <div className={classNames({
        [styles.mobileDateFieldWrapper]: true,
        [styles.toDateField]: true,
      })}>
        <ReactDatePickerStyle>
          <ReactDatePicker
            selected={endDate.toDate()}
            excludeDates={dateInfo.nonWorkingDays.dates}
            focusSelectedMonth={true}
            allowSameDay={true}
            minDate={startDate.toDate()}
            maxDate={startDate.add(1, 'year').toDate()}
            dateFormat={'dd.MM.yyyy'}
            customInput={
              <Input
                id={`global-search-to-date-${identifier}-mobile`}
                name={`global-search-to-date-${identifier}-mobile`}
                type='readonly'
                label={t('search-component:label-end-date')}
                suffix={
                  <Icon size={'25px'} icon={'calendar'} color={'var(--primary-color)'} />
                }
                suffixAction={'focus'}
              />
            }
            previousMonthButtonLabel={<Icon icon={'right-slide'} size={'25px'} />}
            nextMonthButtonLabel={<Icon icon={'right-slide'} size={'25px'} />}
            onChange={(date): void => {
              if ('object' === typeof date) {
                setEndDate(dayjs(date as Date));
              }
            }}
            disabledKeyboardNavigation={true}
            showPopperArrow={false}
            showWeekNumbers
            popperPlacement={'bottom-end'}
            popperModifiers={[
              {
                name: "flip",
                options: {
                  fallbackPlacements: ["bottom-end"],
                  allowedAutoPlacements: ["bottom-end"],
                },
              },
            ]}
          />
        </ReactDatePickerStyle>
      </div>
    </>
  );
};
