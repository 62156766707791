import { atom } from 'recoil';

export const isGlobalSearchHeaderActiveState = atom<boolean>({
  key: 'isGlobalSearchHeaderActive',
  default: false,
});


export const isPageHeaderSearchDropdownVisibleState = atom<boolean>({
  key: 'isPageHeaderSearchDropdownVisible',
  default: false,
});
